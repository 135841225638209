/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable';
import 'regenerator-runtime/runtime';

$(document).ready(function () {

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  var copyrightButton = document.getElementById('copyright-button');
  var video = document.getElementById('sprout-video-player')

  if (copyrightButton && video) {
    var player = new SV.Player({ videoId: 'a791d1b01a12edc42e' });

    player.bind('completed', function () {
      copyrightButton.classList.remove('d-none');
      copyrightButton.classList.add('d-flex');
    });
  }
});
